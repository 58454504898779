import {
  ADD_DESIGN_CHALLENGE_LIST,
  GET_DESIGN_CHALLENGE_LISTS,
  GET_DESIGN_CHALLENGE_LIST,
} from '../actions/types';

const initialState = {
  designChallengeList: {},
  designChallengeLists: [],
  loadingDesignChallengeList: true,
  error: {},
};

export default function lists(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DESIGN_CHALLENGE_LISTS:
      return {
        ...state,
        designChallengeList: {},
        designChallengeLists: payload,
        loadingDesignChallengeList: false,
      };
    case GET_DESIGN_CHALLENGE_LIST:
      return {
        ...state,
        designChallengeList: payload,
        designChallengeLists: [],
        loadingDesignChallengeList: false,
      };
    case ADD_DESIGN_CHALLENGE_LIST:
      return {
        ...state,
        designChallengeList: payload,
        designChallengeLists: [payload, ...state.designChallengeList],
        loadingDesignChallengeList: false,
      };
    default:
      return state;
  }
}
