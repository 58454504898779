import { UPDATE_CUSTOM_ORDER_EXPIRATION_TIME } from '../actions/types';

const initialState = {
  customOrder: {},
};

export default function cache(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CUSTOM_ORDER_EXPIRATION_TIME:
      return {
        ...state,
        customOrder: payload,
      };
    default:
      return state;
  }
}
