import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';

import PrivateRoute from './components/routing/PrivateRoute';
import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Alert from './components/elements/Alert';
import NoDashboard from './components/pages/NoDashboard';
import Dashboard from './components/pages/Dashboard';
import DesignChallengesDashboard from './components/pages/designChallenges/Dashboard';
import DesignChallengesCards from './components/pages/designChallenges/Cards';
import PhotobooksDashboard from './components/pages/photobooks/Dashboard';
import PhotobooksCards from './components/pages/photobooks/Cards';
import DesignChallengesLists from './components/pages/designChallenges/Lists';
import ExtraInvoices from './components/pages/orderMetrics/ExtraInvoices';
import OrderIssues from './components/pages/orderMetrics/OrderIssues';
import DesignChallengesConfiguration from './components/pages/designChallenges/Configuration';
import Cards from './components/pages/Cards';
import Lists from './components/pages/Lists';
import Configuration from './components/pages/Configuration';
import TrelloOrderMetrics from './components/pages/orderMetrics/TrelloOrderMetrics';

import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import store from './store';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

if (window.location.href.includes('?tkn')) {
  let token = window.location.href.split('?tkn=');
  token = token[1];
  localStorage.setItem('token', token);
}

if (localStorage.token) {
  setAuthToken(localStorage.token);
  store.dispatch(loadUser());
} else {
  // window.location.href = config.AUTH_URL + '?src=' + window.location.href;
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const classes = useStyles();
  return (
    <Provider store={store}>
      <Router>
        <div className={classes.root}>
          <CssBaseline />
          <Header />
          <VerticalNav />
          <main className={classes.content}>
            <Toolbar />
            <Alert />
            <Switch>
              <PrivateRoute
                exact
                path='/'
                component={NoDashboard}
              ></PrivateRoute>
              <PrivateRoute exact path='/dashboard' component={Dashboard} />
              <PrivateRoute exact path='/cards' component={Cards} />
              <PrivateRoute exact path='/lists' component={Lists} />
              <PrivateRoute
                exact
                path='/design-challenges'
                component={DesignChallengesDashboard}
              />
              <PrivateRoute
                exact
                path='/design-challenges-cards'
                component={DesignChallengesCards}
              />
              <PrivateRoute
                exact
                path='/photobooks'
                component={PhotobooksDashboard}
              />
              <PrivateRoute
                exact
                path='/photobooks-cards'
                component={PhotobooksCards}
              />
              <PrivateRoute
                exact
                path='/design-challenges-lists'
                component={DesignChallengesLists}
              />
              <PrivateRoute
                exact
                path='/order-metrics'
                component={TrelloOrderMetrics}
              />
              <PrivateRoute
                exact
                path='/extra-invoices'
                component={ExtraInvoices}
              />
              <PrivateRoute
                exact
                path='/order-issues'
                component={OrderIssues}
              />
              <PrivateRoute
                exact
                path='/design-challenges-configuration'
                component={DesignChallengesConfiguration}
              />
              <PrivateRoute
                exact
                path='/configuration'
                component={Configuration}
              />
            </Switch>
          </main>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
