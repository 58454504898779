import React, { useEffect, Fragment, useState } from 'react';
import * as dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import DayjsUtils from '@date-io/dayjs';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import tableIcons from '../../elements/TableIcons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { setAlert, removeAlert } from '../../../actions/alert';
import {
  getDesignChallengeCards,
  syncDesignChallengeCard,
} from '../../../actions/designChallengeCards';
import { getDesignChallengeLists } from '../../../actions/designChallengeLists';

import Cache from '../../elements/Cache';

import number from '../../../utils/roundNumber';
import timeCalculation from '../../../utils/timeCalculation';

const useStyles = makeStyles((theme) => ({
  link: {
    color: grey[900],
  },
  nameRender: {},
  accordion: {
    marginBottom: theme.spacing(1),
  },
}));

const calculateLeadTime = (parameters) => {
  const { card } = parameters;
  if ('leadTime' in card) {
    return card.leadTime;
  }
  return {
    createdTime: '0',
    difference: '0',
    latestTime: '0',
  };
};
const calculateTotalCycleTime = (parameters) => {
  const { cycleTime, lists } = parameters;
  let totalResult = 0;
  if (cycleTime) {
    lists.forEach((list) => {
      totalResult += timeCalculation.totalCycleTime({
        cycleTime: cycleTime,
        list: list,
      }).totalResult;
    });
  }
  return number.round(totalResult);
};

const NameRender = ({ data, onSyncClick }) => {
  const classes = useStyles();
  const dateObject = new Date(data.dateCreated * 1000);
  return (
    <div className={classes.nameRender}>
      <Link
        target='_blank'
        rel='noreferrer'
        className={classes.link}
        href={data.url}
      >
        {data.name}
      </Link>
      <br />
      <small>
        Card created: {dayjs(dateObject).format('MM-DD-YYYY HH:mm:ss')}
      </small>
      <br />
      <small>
        Last activity: {dayjs(data.lastActivity).format('MM-DD-YYYY HH:mm:ss')}
      </small>
      <br />
      <Button
        size='small'
        variant='contained'
        color='secondary'
        onClick={(e) => onSyncClick(e, data.cardId)}
      >
        sync card
      </Button>
    </div>
  );
};

const parseCycleTime = (parameters) => {
  const { card, lists } = parameters;
  let parsedCycleTime = [];
  if ('cycleTime' in card) {
    const cycleTime = card.cycleTime;
    lists.forEach((list) => {
      let result = cycleTime.filter((item) => {
        return item.lisAfterId === list.id;
      });

      let tempRes = 0;
      if (result.length > 0) {
        result.forEach((item) => {
          tempRes += 'timeSpentRoundUp' in item ? item.timeSpentRoundUp * 1 : 0;
        });
      }
      const finalResult = tempRes > 0 ? tempRes : 0;
      parsedCycleTime[`${list.id}`] = number.round(finalResult);
    });
  }
  return parsedCycleTime;
};

const Cards = ({
  designChallengesCards: { designChallengeCards, loadingDesignChallengeCard },
  designChallengesLists: { designChallengeLists, loadingDesignChallengeList },
  getDesignChallengeCards,
  syncDesignChallengeCard,
  getDesignChallengeLists,
  setAlert,
  removeAlert,
}) => {
  const classes = useStyles();

  let items = [];
  let tableColumns,
    csvHeader,
    csvData = [];

  const [startDate, handleStartChange] = useState(new Date());
  const [endDate, handleEndChange] = useState(new Date());
  const [filterState, filterStateChange] = useState(false);

  const onSyncClick = async (e, id) => {
    syncDesignChallengeCard(id);
  };
  const onDatePickerOpen = () => {
    filterStateChange(false);
  };

  const onClickFilter = async () => {
    if (filterState) {
      setAlert('Removing filter', 'info');
      filterStateChange(false);
      getDesignChallengeCards();
    } else {
      setAlert('Applying filter', 'info');
      filterStateChange(true);
      const start = dayjs(startDate).format('MM-DD-YYYY HH:mm:ss');
      const end = dayjs(endDate).format('MM-DD-YYYY HH:mm:ss');
      getDesignChallengeCards({ start: start, end: end });
    }
  };

  useEffect(() => {
    setAlert('Calculating Trello card turn over', 'info');
    getDesignChallengeLists();
    getDesignChallengeCards();
  }, [getDesignChallengeCards, getDesignChallengeLists]);

  if (!loadingDesignChallengeCard) {
    designChallengeLists = designChallengeLists.filter((item) => {
      return item.includeOnCalc == 'true';
    });
    designChallengeLists.sort((a, b) => (a.order > b.order ? 1 : -1));

    tableColumns = [
      {
        title: 'Card Name',
        cellStyle: {
          width: '300px',
          whiteSpace: 'nowrap',
        },
        headerStyle: {
          width: '300px',
          whiteSpace: 'nowrap',
        },
        field: 'name',
        render: (rowData) => (
          <NameRender onSyncClick={onSyncClick} data={rowData}></NameRender>
        ),
      },
    ];
    csvHeader = [
      { label: 'Card Name', key: 'cardName' },
      { label: 'URL', key: 'cardUrl' },
      { label: 'Date Created', key: 'dateCreated' },
    ];
    designChallengeLists.forEach((list) => {
      tableColumns.push({
        title: list.name,
        align: 'center',
        headerStyle: {
          width: 100,
          maxWidth: 100,
        },
        cellStyle: {
          width: 100,
          maxWidth: 100,
        },
        render: (rowData) => (
          <Fragment>
            {rowData.cycleTimeData[`${list.id}`]
              ? rowData.cycleTimeData[`${list.id}`]
              : 0}
          </Fragment>
        ),
      });
      csvHeader.push({
        label: list.name,
        key: `cycleTimeData.${list.id}`,
      });
    });
    tableColumns.push(
      {
        title: 'Cycle Time',
        align: 'center',
        cellStyle: {
          width: '25px',
          whiteSpace: 'nowrap',
        },
        headerStyle: {
          width: '25px',
          whiteSpace: 'nowrap',
        },
        render: (rowData) => <Fragment>{rowData.leadTime.difference}</Fragment>,
      },
      {
        title: 'Lead Time',
        align: 'center',
        cellStyle: {
          width: '25px',
          whiteSpace: 'nowrap',
        },
        headerStyle: {
          width: '25px',
          whiteSpace: 'nowrap',
        },
        render: (rowData) => <Fragment>{rowData.totalCycleTime}</Fragment>,
      }
    );
    csvHeader.push(
      {
        label: 'Cycle Time',
        key: 'totalCycleTime',
      },
      {
        label: 'Lead Time',
        key: 'leadTime',
      }
    );
    designChallengeCards.forEach((card) => {
      items.push({
        name: card.name,
        url: card.url,
        cardId: card.cardId,
        lastActivity: card.dateLastActivity,
        dateCreated: card.dateCreated,
        leadTime: calculateLeadTime({ card: card }),
        totalCycleTime: calculateTotalCycleTime({
          lists: designChallengeLists,
          cycleTime: card.cycleTime,
        }),
        cycleTimeData: parseCycleTime({
          card: card,
          lists: designChallengeLists,
        }),
      });
    });
    items.forEach((item) => {
      let jsonDataOne = {
        cardName: item.name,
        cardUrl: item.url,
        dateCreated: item.dateCreated,
        leadTime: item.leadTime.difference,
        totalCycleTime: item.totalCycleTime,
        cycleTimeData: Object.assign({}, item.cycleTimeData),
      };
      csvData.push(jsonDataOne);
    });
    removeAlert({ source: 'getCards' });
  }

  return !loadingDesignChallengeCard && !loadingDesignChallengeList ? (
    <Fragment>
      <Cache />
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h6'>Filter & Export</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardContent>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <DatePicker
                  autoOk={true}
                  variant='inline'
                  value={startDate}
                  label='Start Date'
                  onChange={handleStartChange}
                  onOpen={() => onDatePickerOpen()}
                ></DatePicker>{' '}
                <DatePicker
                  autoOk={true}
                  variant='inline'
                  value={endDate}
                  label='End Date'
                  onChange={handleEndChange}
                  onOpen={() => onDatePickerOpen()}
                ></DatePicker>
              </MuiPickersUtilsProvider>
            </CardContent>
            <CardActions>
              <Button
                size='small'
                onClick={() => onClickFilter()}
                variant='contained'
                color='secondary'
              >
                {filterState ? 'Clear Filter' : 'Filter'}
              </Button>
              <CSVLink
                data={csvData}
                headers={csvHeader}
                filename={`co-board-${Date.now()}.csv`}
                className='btn btn-primary'
                target='_blank'
              >
                <Button size='small' variant='contained' color='secondary'>
                  Download
                </Button>
              </CSVLink>
            </CardActions>
            <CardContent>
              <Typography variant='body2'>
                {filterState
                  ? `Filter applied ${dayjs(startDate).format(
                      'MM-DD-YYYY'
                    )} - ${dayjs(endDate).format('MM-DD-YYYY')}`
                  : ''}
              </Typography>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
      <MaterialTable
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          headerStyle: {
            fontWeight: 'bold',
          },
        }}
        icons={tableIcons}
        columns={tableColumns}
        data={items}
        title='Cards on Design Challenges Board'
      />
    </Fragment>
  ) : (
    <LinearProgress color='secondary' />
  );
};

Cards.propTypes = {
  designChallengesCards: PropTypes.object.isRequired,
  designChallengesLists: PropTypes.object.isRequired,
  getDesignChallengeCards: PropTypes.func.isRequired,
  syncDesignChallengeCard: PropTypes.func.isRequired,
  getDesignChallengeLists: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  designChallengesCards: state.designChallengesCards,
  designChallengesLists: state.designChallengesLists,
});

export default connect(mapStateToProps, {
  getDesignChallengeCards,
  syncDesignChallengeCard,
  getDesignChallengeLists,
  setAlert,
  removeAlert,
})(Cards);
