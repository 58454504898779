import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { getExtraLabel } from '../../../actions/extraInvoiceLabel';
import { setAlert } from '../../../actions/alert';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modalPaper: {
    position: 'absolute',
    top: '10%',
    left: '25%',
    width: 1024,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
}));

const ModalWrap = ({ openModal, handleModalClose, modalBody }) => {
  const classes = useStyles();
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      {modalBody && (
        <div className={classes.modalPaper}>
          <h2 id='simple-modal-title'>PK: {modalBody.id}</h2>
          <hr />
          <p>
            <strong>Trello Cards:</strong>
          </p>
          <Grid container spacing={1}>
            <Grid item>
              <ul>
                {modalBody.value.map((item) => {
                  return (
                    <li key={item.ud}>
                      <a href={item.shortUrl} target='_blank' rel='noreferrer'>
                        {item.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
        </div>
      )}
    </Modal>
  );
};

const ExtraInvoices = ({
  extraInvoice: { extraInvoices, loadingExtraInvoice },
  getExtraLabel,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState(undefined);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setAlert('Fetching dashboard data', 'info');
    getExtraLabel();
  }, [getExtraLabel]);

  const onClickOpenModal = (e, data) => {
    setOpenModal(true);
    setModalBody(data);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const columns = [
    {
      field: 'period',
      headerName: 'Period',
      width: 175,
      headerAlign: 'center',
    },
    {
      field: 'count',
      headerName: 'Count',
      width: 175,
      headerAlign: 'center',
    },
    {
      field: 'card',
      headerName: 'Card',
      width: 300,
      headerAlign: 'centers',
      renderCell: (params) => {
        return (
          <Fragment>
            <Button
              size='small'
              variant='contained'
              color='secondary'
              onClick={(e) => onClickOpenModal(e, params)}
            >
              open detail
            </Button>
          </Fragment>
        );
      },
    },
  ];
  const items = extraInvoices
    ? extraInvoices.map((item) => {
        return {
          id: `${item.PK}#${item.SK}`,
          period: item.SK,
          card: item.Cards,
          count: item.Count,
        };
      })
    : [];

  return !loadingExtraInvoice ? (
    <div className={classes.root}>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <Tab label='Extra Invoices' {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div style={{ height: 400, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={items}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <ModalWrap
        openModal={openModal}
        modalBody={modalBody}
        handleModalClose={handleModalClose}
      />
    </div>
  ) : (
    <LinearProgress color='secondary' />
  );
};

ExtraInvoices.propTypes = {
  extraInvoice: PropTypes.object.isRequired,
  getExtraLabel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  extraInvoice: state.extraInvoice,
});
export default connect(mapStateToProps, { getExtraLabel })(ExtraInvoices);
