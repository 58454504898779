import { GET_EXTRA_LABEL } from '../actions/types';

const initialState = {
  extraInvoices: [],
  loadingExtraInvoice: true,
  error: {},
};

export default function extraInvoice(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_EXTRA_LABEL:
      return {
        ...state,
        extraInvoices: payload,
        loadingExtraInvoice: false,
      };
    default:
      return state;
  }
}
