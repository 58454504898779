import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import MaterialTable from 'material-table';
import tableIcons from '../elements/TableIcons';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {getLists, updateList} from '../../actions/lists';
import {
  getPlatformOrderLists,
  updatePlatformOrderList,
} from '../../actions/platformOrderList';
import {setAlert, removeAlert} from '../../actions/alert';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Lists = ({
  lists: {lists, loadingList},
  platformOrderLists: {loadingPlatformLists, platformLists},
  getLists,
  updateList,
  getPlatformOrderLists,
  updatePlatformOrderList,
  setAlert,
  removeAlert,
}) => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  let items = [];
  let platformOrderItems = [];
  useEffect(() => {
    setAlert('Fetching Custom Order Board List', 'info');
    getLists();
  }, [getLists]);

  useEffect(() => {
    setAlert('Fetching Platform Order Board List', 'info');
    getPlatformOrderLists();
  }, [getPlatformOrderLists]);

  if (!loadingList && lists.length > 0) {
    lists.forEach((list) => {
      items.push({
        name: list.name,
        order: list.order,
        includeOnCalc: list.includeOnCalc,
        id: list.id,
        PK: list.PK,
        SK: list.SK,
      });
    });
  }

  if (!loadingPlatformLists && platformLists.length > 0) {
    platformLists.forEach((list) => {
      platformOrderItems.push({
        name: list.name,
        PK: list.PK,
        id: list.id,
        SK: list.SK,
        showOnList: list.showOnList,
      });
    });
  }

  const tableCustomOrderColumns = [
    {
      title: 'List Name',
      field: 'name',
      editable: 'never',
      sorting: true,
    },
    {title: 'Order', field: 'order', defaultSort: 'asc'},
    {
      title: 'Include On Calculation',
      field: 'includeOnCalc',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: true,
    },
  ];

  const tablePlatformOrderColumns = [
    {
      title: 'List Name',

      field: 'name',
      editable: 'never',
      sorting: true,
    },
    {
      title: 'Show On Lists',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      field: 'showOnList',
      sorting: true,
    },
  ];
  removeAlert({source: 'list dashboard'});
  return !loadingList ? (
    <Fragment>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label='Kandidat tab'
        >
          <Tab label='Custom Order Board' {...a11yProps(0)} />
          <Tab label='Platform Order Board' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MaterialTable
          options={{
            headerStyle: {fontWeight: 'bold'},
            pageSize: 10,
            pageSizeOptions: [5, 10, 25],
          }}
          icons={tableIcons}
          columns={tableCustomOrderColumns}
          data={items}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                updateList({
                  newValue: newValue,
                  rowData: rowData,
                  columnDef: columnDef,
                });
                setTimeout(resolve, 1000);
              });
            },
          }}
          title='Custom Order Board Lists'
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialTable
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            headerStyle: {
              fontWeight: 'bold',
            },
          }}
          icons={tableIcons}
          columns={tablePlatformOrderColumns}
          data={platformOrderItems}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                updatePlatformOrderList({
                  newValue: newValue,
                  rowData: rowData,
                  columnDef: columnDef,
                });
                setTimeout(resolve, 1000);
              });
            },
          }}
          title='Platform Order Board Lists'
        />
      </TabPanel>
    </Fragment>
  ) : (
    <LinearProgress color='secondary' />
  );
};

Lists.propTypes = {
  getLists: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  getPlatformOrderLists: PropTypes.func.isRequired,
  updatePlatformOrderList: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
  lists: PropTypes.object.isRequired,
  platformOrderLists: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  lists: state.lists,
  platformOrderLists: state.platformOrderLists,
});

export default connect(mapStateToProps, {
  getLists,
  updateList,
  getPlatformOrderLists,
  updatePlatformOrderList,
  setAlert,
  removeAlert,
})(Lists);
