import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';

import { designChallengeSync } from '../../../actions/designChallengeSync';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonTop: {
    marginTop: theme.spacing(1),
  },
  card: {
    marginTop: theme.spacing(1),
  },
  field: {
    marginRight: theme.spacing(1),
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Configuration = ({ designChallengeSync }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const onDesignChallengeSync = (e, type) => {
    designChallengeSync(type);
  };

  return (
    <Fragment>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label='Settings tab'
        >
          <Tab label='Design Challenges Board' {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Card className={classes.card}>
          <CardHeader
            title='Design Challenges Board - Card Turn Over'
            subheader='Manually sync the trello data to the turn over calculation'
          ></CardHeader>
          <CardContent>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              onClick={(e) => onDesignChallengeSync(e, 'lists')}
            >
              Sync Lists
            </Button>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              onClick={(e) => onDesignChallengeSync(e, 'cards')}
            >
              Sync Cards
            </Button>
          </CardContent>
        </Card>
      </TabPanel>
    </Fragment>
  );
};

Configuration.propTypes = {
  designChallengeSync: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { designChallengeSync })(Configuration);
