import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { cacheToLive, clearCache } from '../../utils/cache';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  accordion: {
    marginBottom: theme.spacing(1),
  },
}));

const Cache = ({
  cards: { cards, loadingCard },
  lists: { lists, loadingList },
  cache,
  clearCache,
}) => {
  const onClickClearCache = () => {
    clearCache();
  };

  const classes = useStyles();
  const timeNow = dayjs(Date.now());
  const timeCacheCreation = dayjs(cache.customOrder);
  const cacheAge = timeNow.diff(timeCacheCreation, 'm');
  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6'>Cache</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Card className={classes.card} variant='outlined'>
          <CardContent>
            <Typography>
              Custom Order data cache will be update in:{' '}
              {cacheToLive - cacheAge} minutes
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size='small'
              variant='contained'
              color='secondary'
              onClick={() => onClickClearCache()}
            >
              clear cache
            </Button>
          </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

Cache.propTypes = {
  cards: PropTypes.object.isRequired,
  lists: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  clearCache: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cards: state.cards,
  lists: state.lists,
  cache: state.cache,
});

export default connect(mapStateToProps, { clearCache })(Cache);
