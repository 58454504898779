import {ADD_LIST, GET_LISTS, GET_LIST} from '../actions/types';

const initialState = {
  list: {},
  lists: [],
  loadingList: true,
  error: {},
};

export default function lists(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_LISTS:
      return {
        ...state,
        list: {},
        lists: payload,
        loadingList: false,
      };
    case GET_LIST:
      return {
        ...state,
        list: payload,
        lists: [],
        loadingList: false,
      };
    case ADD_LIST:
      return {
        ...state,
        list: payload,
        lists: [payload, ...state.lists],
        loadingList: false,
      };
    default:
      return state;
  }
}
