import {
  ADD_PLATFORM_ORDER_LIST,
  GET_PLATFORM_ORDER_LISTS,
  GET_PLATFORM_ORDER_LIST,
} from '../actions/types';

const initialState = {
  platformList: {},
  platformLists: [],
  loadingPlatformLists: true,
  error: {},
};

export default function platformOrderLists(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_PLATFORM_ORDER_LISTS:
      return {
        ...state,
        platformList: {},
        platformLists: payload,
        loadingPlatformLists: false,
      };
    case GET_PLATFORM_ORDER_LIST:
      return {
        ...state,
        platformList: payload,
        platformLists: [],
        loadingPlatformLists: false,
      };
    case ADD_PLATFORM_ORDER_LIST:
      return {
        ...state,
        platformList: payload,
        platformLists: [payload, ...state.platformLists],
        loadingPlatformLists: false,
      };
    default:
      return state;
  }
}
