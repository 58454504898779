import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { CSVLink } from 'react-csv';
import DayjsUtils from '@date-io/dayjs';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import MaterialTable from 'material-table';
import tableIcons from '../../elements/TableIcons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { getTrelloMetrics } from '../../../actions/metrics';
import { setAlert } from '../../../actions/alert';
dayjs.extend(quarterOfYear);

const NameRender = ({ data }) => {
  let users = data.userMetrics;
  users = users.map((item) => {
    return item.text;
  });
  users = [...new Set(users)];
  return <Fragment>{users.join(', ')}</Fragment>;
};

const CardRender = ({ data }) => {
  let cards = data.trelloCards;
  cards = cards.map((item) => {
    const creationDate = new Date(1000 * parseInt(item.id.substring(0, 8), 16));
    return (
      <Fragment key={item.id}>
        <a rel='noreferrer' target='_blank' href={item.url}>
          {item.name}
        </a>
        <br />
        <small>id: {item.id}</small>
        <br />
        <small>
          Date Created: {dayjs(creationDate).format('DD-MM-YYYY HH:mm:ss')}
        </small>
        <hr />
      </Fragment>
    );
  });
  return cards;
};

const HistoryRender = ({ data }) => {
  const cards = data.map((item) => {
    return (
      <Fragment key={item.SK}>
        <a
          rel='noreferrer'
          target='_blank'
          href={`https://trello.com/c/${item.card.display.entities.card.shortLink}`}
        >
          {item.card.display.entities.card.text}
        </a>
        <br />
        <small>PK: {item.PK}</small> | <small>SK: {item.SK}</small>
        <br />
        <small>
          Last Activity: {dayjs(item.dateCreated).format('DD-MM-YYYY HH:mm:ss')}
        </small>
        <hr />
      </Fragment>
    );
  });
  return cards;
};

const ModalWrap = ({ openModal, handleModalClose, modalBody }) => {
  const classes = useStyles();

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      {modalBody && (
        <div className={classes.modalPaper}>
          <h2 id='simple-modal-title'>PK: {modalBody.PK}</h2>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p>
                <strong>SK:</strong> {modalBody.SK}
                <br />{' '}
                <small>
                  <strong>Date Created:</strong>{' '}
                  {dayjs(modalBody.dateCreated).format('DD-MM-YYYY HH:mm:ss')}
                </small>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                <strong>List:</strong>
                <br />
                <strong>Name:</strong> {modalBody.trelloMetrics.list.name}
                <br />
                <strong>id:</strong> {modalBody.trelloMetrics.list.id}
              </p>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p>
                <strong>User metrics:</strong> <br />
                <NameRender data={modalBody}></NameRender>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                <strong>Trello Metrics:</strong> <br />
                card moved: {modalBody.trelloMetrics.cardMoved}
                <br />
                card left:{' '}
                {modalBody.trelloMetrics.cardMovedAfter3PM
                  ? modalBody.trelloMetrics.cardLeft -
                    modalBody.trelloMetrics.cardMovedAfter3PM
                  : modalBody.trelloMetrics.cardLeft}
                <br />
                card moved after 3pm:{' '}
                {modalBody.trelloMetrics.cardMovedAfter3PM
                  ? modalBody.trelloMetrics.cardMovedAfter3PM
                  : '-'}
              </p>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p>
                <strong>Trello card:</strong> <br />
                <CardRender data={modalBody}></CardRender>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                <strong>History card:</strong> <br />
                <HistoryRender data={modalBody.history}></HistoryRender>
              </p>
            </Grid>
          </Grid>
          <hr />
        </div>
      )}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: grey[900],
  },
  nameRender: {},
  accordion: {
    marginBottom: theme.spacing(1),
  },
  modalPaper: {
    position: 'absolute',
    width: 1024,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
}));

const TrelloOrderMetrics = ({
  metrics: { loadingMetric, metrics },
  platformOrderLists: { loadingPlatformLists, platformLists },
  getTrelloMetrics,
  setAlert,
}) => {
  const classes = useStyles();
  const [startYear, handleYearChange] = useState(new Date());
  const [startQuarter, handleQuarterChange] = useState(dayjs().quarter());
  const [filterState, filterStateChange] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState(undefined);
  // let items = [];
  let csvHeader;
  let csvData = [];
  const onDatePickerOpen = () => {
    filterStateChange(false);
  };

  const quarterChange = (event) => {
    console.log(event.target);
    console.log(event);
    handleQuarterChange(event.target.value);
  };

  const onClickFilter = async () => {
    if (filterState) {
      setAlert('Removing filter', 'info');
      filterStateChange(false);
      getTrelloMetrics();
    } else {
      setAlert('Applying filter', 'info');
      filterStateChange(true);
      const year = dayjs(startYear).format('YYYY');
      const quarter = startQuarter;
      getTrelloMetrics({ year: year, quarter: quarter });
    }
  };

  const onClickOpenModal = (e, data) => {
    setOpenModal(true);
    setModalBody(data);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setAlert('Fetching dashboard data', 'info');
    getTrelloMetrics();
  }, [getTrelloMetrics]);

  const tableColumns = [
    {
      title: 'Date',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: true,
      customSort: (a, b) => a.dateCreated - b.dateCreated,
      defaultSort: 'desc',
      render: (rowData) => (
        <Fragment>{dayjs(rowData.dateCreated).format('MM-DD-YYYY')}</Fragment>
      ),
    },
    {
      title: 'List',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: false,
      render: (rowData) => (
        <Fragment>
          {rowData.trelloMetrics.list.name}
          <br />
          <Button
            size='small'
            variant='contained'
            color='secondary'
            onClick={(e) => onClickOpenModal(e, rowData)}
          >
            open detail
          </Button>
        </Fragment>
      ),
    },
    {
      title: 'User',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: false,
      render: (rowData) => <NameRender data={rowData}></NameRender>,
    },
    {
      title: 'Total Moving Card',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: false,
      render: (rowData) => (
        <Fragment>{rowData.trelloMetrics.cardMoved}</Fragment>
      ),
    },
    {
      title: 'Cards left on the list',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: false,
      render: (rowData) => (
        <Fragment>
          {rowData.trelloMetrics.cardMovedAfter3PM
            ? rowData.trelloMetrics.cardLeft -
              rowData.trelloMetrics.cardMovedAfter3PM
            : rowData.trelloMetrics.cardLeft}
        </Fragment>
      ),
    },
    {
      title: 'New cards (after 3pm)',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: false,
      render: (rowData) => (
        <Fragment>
          {rowData.trelloMetrics.cardMovedAfter3PM
            ? rowData.trelloMetrics.cardMovedAfter3PM
            : '-'}
        </Fragment>
      ),
    },
  ];
  csvHeader = [
    { label: 'Date', key: 'date' },
    { label: 'List', key: 'list' },
    { label: 'User', key: 'user' },
    { label: 'Total Moving Card', key: 'totalMovingCard' },
    { label: 'Card Left on the list', key: 'cardLeftOnTheList' },
    { label: 'New cards (after 3pm)', key: 'newCardAfter3PM' },
  ];
  if (!loadingMetric && metrics.length > 0) {
    metrics.forEach((item) => {
      let users = item.userMetrics;
      users = users.map((item) => {
        return item.text;
      });
      users = [...new Set(users)];
      let jsonData = {
        date: dayjs(item.dateCreated).format('MM-DD-YYYY'),
        list: item.trelloMetrics.list.name,
        user: users.join(', '),
        totalMovingCard: item.trelloMetrics.cardMoved,
        cardLeftOnTheList:
          item.trelloMetrics.cardLeft - item.trelloMetrics.cardMovedAfter3PM,
        newCardAfter3PM: item.trelloMetrics.cardMovedAfter3PM,
      };
      csvData.push(jsonData);
    });
  }
  return !loadingMetric && metrics.length > 0 ? (
    <Fragment>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h6'>Filter & Export</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardContent>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <DatePicker
                  views={['year']}
                  autoOk={true}
                  variant='inline'
                  value={startYear}
                  label='Start Date'
                  onChange={handleYearChange}
                  onOpen={() => onDatePickerOpen()}
                ></DatePicker>{' '}
              </MuiPickersUtilsProvider>
              <Select
                value={startQuarter}
                label='Quarter'
                onChange={quarterChange}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </CardContent>
            <CardActions>
              <Button
                size='small'
                onClick={() => onClickFilter()}
                variant='contained'
                color='secondary'
              >
                {filterState ? 'Clear Filter' : 'Filter'}
              </Button>
              <CSVLink
                data={csvData}
                headers={csvHeader}
                filename={`platform-order-board-${startYear}-${startQuarter}.csv`}
                className='btn btn-primary'
                target='_blank'
              >
                <Button size='small' variant='contained' color='secondary'>
                  Download
                </Button>
              </CSVLink>
            </CardActions>
            <CardContent>
              <Typography variant='body2'>
                {filterState
                  ? `Filter applied ${dayjs(startYear).format(
                      'MM-DD-YYYY'
                    )} - ${dayjs(startQuarter).format('MM-DD-YYYY')}`
                  : ''}
              </Typography>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
      <ModalWrap
        openModal={openModal}
        modalBody={modalBody}
        handleModalClose={handleModalClose}
      />
      <MaterialTable
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          headerStyle: {
            fontWeight: 'bold',
          },
        }}
        icons={tableIcons}
        columns={tableColumns}
        data={metrics}
        title='Platform Order - Cleansheet'
      />
    </Fragment>
  ) : (
    <LinearProgress color='secondary' />
  );
};

TrelloOrderMetrics.propTypes = {
  metrics: PropTypes.object.isRequired,
  platformOrderLists: PropTypes.object.isRequired,
  getTrelloMetrics: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  metrics: state.metrics,
  platformOrderLists: state.platformOrderLists,
});

export default connect(mapStateToProps, {
  getTrelloMetrics,
  setAlert,
})(TrelloOrderMetrics);
