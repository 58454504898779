import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import number from '../../../utils/roundNumber';
import timeCalculation from '../../../utils/timeCalculation';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Cache from '../../elements/Cache';

import { getPhotobookCards } from '../../../actions/cards';
import { getLists } from '../../../actions/lists';
import { setAlert, removeAlert } from '../../../actions/alert';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
}));

const RenderCycleTime = ({ cycleTime, lists }) => {
  const classes = useStyles();
  return lists.map((item) => {
    return (
      <Card variant='outlined' className={classes.card}>
        <CardContent>
          {cycleTime[item.id].list.name}
          <br />
          {/* <small>
            Total Cycle Days: {number.round(cycleTime[item.id].totalCycleDay)}
          </small>
          <br />
          <small>
            Sum Cycle Days: {number.round(cycleTime[item.id].sumCycleDay)}
          </small>
          <br /> */}
          <small>
            Average Cycle Days:{' '}
            {number.round(cycleTime[item.id].averageCycleDay)}
          </small>
        </CardContent>
      </Card>
    );
  });
};

const Dashboard = ({
  photobooksCards: { photobooksCards, loadingPhotobooksCard },
  lists: { lists, loadingLists },
  getPhotobookCards,
  getLists,
  setAlert,
  removeAlert,
}) => {
  const classes = useStyles();
  const [startDate, handleStartChange] = useState(new Date());
  const [endDate, handleEndChange] = useState(new Date());
  const [filterState, filterStateChange] = useState(false);

  const onDatePickerOpen = () => {
    filterStateChange(false);
  };

  const onClickFilter = async () => {
    if (filterState) {
      setAlert('Removing filter', 'info');
      filterStateChange(false);
      getPhotobookCards();
    } else {
      setAlert('Applying filter', 'info');
      filterStateChange(true);
      const start = dayjs(startDate).format('MM-DD-YYYY HH:mm:ss');
      const end = dayjs(endDate).format('MM-DD-YYYY HH:mm:ss');
      getPhotobookCards({ start: start, end: end });
    }
  };

  useEffect(() => {
    setAlert('Fetching dashboard data', 'info');
    getLists();
    getPhotobookCards();
  }, [getPhotobookCards, getLists]);
  let leadTime = 0;
  let cycleTime = [];
  lists = lists.filter((item) => {
    return item.includeOnCalc == 'true';
  });
  lists.sort((a, b) => (a.order > b.order ? 1 : -1));
  lists.forEach((list) => {
    cycleTime[list.id] = {
      list: list,
      totalCycleDay: 0,
      sumCycleDay: 0,
    };
  });
  photobooksCards.forEach((card) => {
    leadTime += 'leadTime' in card ? card.leadTime.difference * 1 : 0;
    if ('cycleTime' in card) {
      lists.forEach((list) => {
        const cycleCalculation = timeCalculation.totalCycleTime({
          cycleTime: card.cycleTime,
          list: list,
        });
        const newTotalCycleDay =
          cycleTime[list.id].totalCycleDay + cycleCalculation.totalCycleDay;
        const newSumCycleDay =
          cycleTime[list.id].sumCycleDay + cycleCalculation.totalResult;
        const newAverageCycleDay = newSumCycleDay / newTotalCycleDay;
        cycleTime[list.id] = {
          list: list,
          totalCycleDay: newTotalCycleDay,
          sumCycleDay: newSumCycleDay,
          averageCycleDay: newAverageCycleDay,
        };
      });
    }
  });
  removeAlert({ source: 'dashboard' });
  return !loadingPhotobooksCard ? (
    <Card>
      <CardHeader title='Photobooks - Custom Order Turn Over Time'></CardHeader>
      <CardContent>
        <Cache />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Card className={classes.card} variant='outlined'>
              <CardHeader subheader='Statistic of Custom Trello Board'></CardHeader>
              <CardContent>
                <Typography>Total Card: {photobooksCards.length}</Typography>
                <Typography>
                  Lead Time Average:{' '}
                  {number.round(leadTime / photobooksCards.length)}
                </Typography>
                <Typography>Cycle Time Average</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card} variant='outlined'>
              <CardHeader subheader='Filter'></CardHeader>
              <CardContent>
                <MuiPickersUtilsProvider utils={DayjsUtils}>
                  <DatePicker
                    autoOk={true}
                    variant='inline'
                    value={startDate}
                    label='Start Date'
                    onChange={handleStartChange}
                    onOpen={() => onDatePickerOpen()}
                  ></DatePicker>{' '}
                  <DatePicker
                    autoOk={true}
                    variant='inline'
                    value={endDate}
                    label='End Date'
                    onChange={handleEndChange}
                    onOpen={() => onDatePickerOpen()}
                  ></DatePicker>
                </MuiPickersUtilsProvider>
              </CardContent>
              <CardActions>
                <Button
                  size='small'
                  onClick={() => onClickFilter()}
                  variant='contained'
                  color='secondary'
                >
                  {filterState ? 'Clear Filter' : 'Filter'}
                </Button>
                <Typography variant='body2'>
                  {filterState
                    ? `Filter applied ${dayjs(startDate).format(
                        'MM-DD-YYYY'
                      )} - ${dayjs(endDate).format('MM-DD-YYYY')}`
                    : ''}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <RenderCycleTime cycleTime={cycleTime} lists={lists}></RenderCycleTime>
      </CardContent>
    </Card>
  ) : (
    <LinearProgress color='secondary' />
  );
};

Dashboard.propTypes = {
  photobooksCards: PropTypes.object.isRequired,
  lists: PropTypes.object.isRequired,
  getPhotobookCards: PropTypes.func.isRequired,
  getLists: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  photobooksCards: state.photobooksCards,
  lists: state.lists,
});

export default connect(mapStateToProps, {
  getPhotobookCards,
  getLists,
  setAlert,
  removeAlert,
})(Dashboard);
