const timeCalculation = {
  totalCycleTime: (parameters) => {
    const { cycleTime, list } = parameters;
    let totalList = 0;
    let totalCycleDay = 0;
    const result = cycleTime.filter((item) => {
      return item.lisAfterId === list.id;
    });
    if (result.length > 0) {
      result.forEach((item) => {
        totalList += 'timeSpentRoundUp' in item ? item.timeSpentRoundUp * 1 : 0;
      });
      totalCycleDay += 1;
    }
    return {
      totalCycleDay: totalCycleDay,
      totalResult: totalList > 0 ? totalList / result.length : 0,
    };
  },
};
export default timeCalculation;
