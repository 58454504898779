import {
  ADD_DESIGN_CHALLENGE_CARD,
  GET_DESIGN_CHALLENGE_CARDS,
  GET_DESIGN_CHALLENGE_CARD,
} from '../actions/types';

const initialState = {
  designChallengeCard: {},
  designChallengeCards: [],
  loadingDesignChallengeCard: true,
  error: {},
};

export default function cards(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DESIGN_CHALLENGE_CARDS:
      return {
        ...state,
        designChallengeCard: {},
        designChallengeCards: payload,
        loadingDesignChallengeCard: false,
      };
    case GET_DESIGN_CHALLENGE_CARD:
      return {
        ...state,
        designChallengeCard: payload,
        designChallengeCards: [],
        loadingDesignChallengeCard: false,
      };
    case ADD_DESIGN_CHALLENGE_CARD:
      return {
        ...state,
        designChallengeCard: payload,
        designChallengeCards: [payload, ...state.designChallengeCard],
        loadingDesignChallengeCard: false,
      };
    default:
      return state;
  }
}
