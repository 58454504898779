import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import MaterialTable from 'material-table';
import tableIcons from '../../elements/TableIcons';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import {
  getDesignChallengeLists,
  updateDesignChallengeList,
} from '../../../actions/designChallengeLists';

import { setAlert, removeAlert } from '../../../actions/alert';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Lists = ({
  designChallengesLists: { designChallengeLists, loadingDesignChallengeList },
  getDesignChallengeLists,
  updateDesignChallengeList,
  setAlert,
  removeAlert,
}) => {
  const [value, setValue] = useState(0);
  let items = [];
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setAlert('Fetching Design Challenges Board List', 'info');
    getDesignChallengeLists();
  }, [getDesignChallengeLists]);

  if (!loadingDesignChallengeList && designChallengeLists.length > 0) {
    designChallengeLists.forEach((list) => {
      items.push({
        name: list.name,
        order: list.order,
        includeOnCalc: list.includeOnCalc,
        id: list.id,
        PK: list.PK,
        SK: list.SK,
      });
    });
  }
  const tableDesignChallengesColumns = [
    {
      title: 'List Name',
      field: 'name',
      editable: 'never',
      sorting: true,
    },
    { title: 'Order', field: 'order', defaultSort: 'asc' },
    {
      title: 'Include On Calculation',
      field: 'includeOnCalc',
      cellStyle: {
        width: '250px',
        whiteSpace: 'nowrap',
      },
      sorting: true,
    },
  ];
  removeAlert({ source: 'list dashboard' });
  return !loadingDesignChallengeList ? (
    <Fragment>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label='Kandidat tab'
        >
          <Tab label='Design Challenges Board' {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MaterialTable
          options={{
            headerStyle: { fontWeight: 'bold' },
            pageSize: 10,
            pageSizeOptions: [5, 10, 25],
          }}
          icons={tableIcons}
          columns={tableDesignChallengesColumns}
          data={items}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                updateDesignChallengeList({
                  newValue: newValue,
                  rowData: rowData,
                  columnDef: columnDef,
                });
                setTimeout(resolve, 1000);
              });
            },
          }}
          title='Design Challenges Board Lists'
        />
      </TabPanel>
    </Fragment>
  ) : (
    <LinearProgress color='secondary' />
  );
};

Lists.propTypes = {
  designChallengesLists: PropTypes.object.isRequired,
  getDesignChallengeLists: PropTypes.func.isRequired,
  updateDesignChallengeList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  designChallengesLists: state.designChallengesLists,
});

export default connect(mapStateToProps, {
  getDesignChallengeLists,
  updateDesignChallengeList,
  setAlert,
  removeAlert,
})(Lists);
