import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const NoDashboard = ({auth: {isAuthenticated, loading}}) => {
  // redirect if logged in
  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return loading ? (
    <Fragment> </Fragment>
  ) : (
    <Fragment>
      <div className='jumbotron'>
        <h1 className='display-4'>{process.env.REACT_APP_TITLE}!</h1>
        <p className='lead'>
          <b>Click</b>{' '}
          <a
            href={
              process.env.REACT_APP_URL_AUTH + '?src=' + window.location.href
            }
          >
            here
          </a>{' '}
          to go to login page.
        </p>
      </div>
    </Fragment>
  );
};

NoDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NoDashboard);
