import axios from 'axios';
import * as dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { GET_METRICS } from '../actions/types';
import { setAlert } from './alert';

export const getTrelloMetrics = (parameters) => async (dispatch) => {
  try {
    console.log(parameters);
    dayjs.extend(quarterOfYear);
    const queryParams = new URLSearchParams({
      year: parameters && parameters.year ? parameters.year : dayjs().year(),
      quarter:
        parameters && parameters.quarter
          ? parameters.quarter
          : dayjs().quarter(),
    });

    const res = await axios.get(
      `${
        process.env.REACT_APP_URL_BACKEND_PLATFORM
      }/metrics?${queryParams.toString()}`
    );
    const data = {
      metrics: res.data.items,
    };

    dispatch({
      type: GET_METRICS,
      payload: data.metrics,
    });
  } catch (error) {
    dispatch(setAlert(error));
    console.error(error);
  }
};
