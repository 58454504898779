import axios from 'axios';
import { GET_EXTRA_LABEL } from '../actions/types';
import { setAlert } from './alert';

export const getExtraLabel = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND_ORDER_ISSUES}/extra-invoices`
    );
    dispatch({
      type: GET_EXTRA_LABEL,
      payload: res.data.items,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};
