import axios from 'axios';
import { GET_LISTS, LISTS, UPDATE_CUSTOM_ORDER_EXPIRATION_TIME } from './types';
import { setAlert } from './alert';
import { read, write } from '../utils/cache';

export const getLists = () => async (dispatch) => {
  try {
    const lists = read(LISTS);
    const timeCreation = Date.now();
    const res = !lists
      ? await axios.get(process.env.REACT_APP_URL_BACKEND + '/lists')
      : lists;
    if (!lists) {
      res.time = timeCreation;
      write(LISTS, res);
    }
    dispatch({
      type: UPDATE_CUSTOM_ORDER_EXPIRATION_TIME,
      payload: !lists ? timeCreation : res.time,
    });
    const data = {
      lists: res.data.items,
    };
    dispatch({
      type: GET_LISTS,
      payload: data.lists,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};

export const updateList = (data) => async (dispatch) => {
  const { newValue, rowData, columnDef } = data;
  try {
    dispatch(setAlert('Saving changes', 'info'));
    const dataToSend = {
      PK: rowData.PK,
      SK: rowData.SK,
      name: rowData.name,
      id: rowData.id,
      order: columnDef.field == 'order' ? newValue : rowData.order,
      includeOnCalc:
        columnDef.field == 'includeOnCalc' ? newValue : rowData.includeOnCalc,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}/lists/${rowData.id}`,
      dataToSend
    );

    const data = {
      lists: res.data.items,
    };
    dispatch({
      type: GET_LISTS,
      payload: data.lists,
    });
    dispatch(
      setAlert(
        'Trello list data updated',
        'success',
        process.env.REACT_APP_CUSTOM_TIMER
      )
    );
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};
