import axios from 'axios';
import { GET_ORDER_ISSUES, GET_PAST_ORDER_ISSUES } from '../actions/types';
import { setAlert } from './alert';

export const getOrderIssues = (parameter) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND_ORDER_ISSUES}/order-issues/${parameter.year}/${parameter.month}`
    );
    dispatch({
      type: GET_ORDER_ISSUES,
      payload: res.data.items,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};

export const getPastOrderIssues = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND_ORDER_ISSUES}/order-issues`
    );
    dispatch({
      type: GET_PAST_ORDER_ISSUES,
      payload: res.data.items,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};
