import React, { useEffect, useState } from 'react';
import * as dayjs from 'dayjs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import {
  getOrderIssues,
  getPastOrderIssues,
} from '../../../actions/orderIssues';
import { setAlert } from '../../../actions/alert';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modalPaper: {
    position: 'absolute',
    top: '10%',
    left: '25%',
    width: 1024,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  typography: {
    fontWeight: 'bold',
  },
  accordion: {
    marginBottom: theme.spacing(1),
  },
}));

const OrderIssues = ({
  orderIssues: { orderIssues, loadingOrderIssues },
  pastOrderIssues: { pastOrderIssues, loadingPastOrderIssues },
  getOrderIssues,
  getPastOrderIssues,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const currentDate = new Date();
  useEffect(() => {
    setAlert('Fetching dashboard data', 'info');
    getOrderIssues({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
    });
  }, [getOrderIssues]);

  useEffect(() => {
    setAlert('Fetching dashboard data', 'info');
    getPastOrderIssues();
  }, [getPastOrderIssues]);

  const orderIssuesColumn = [
    {
      field: 'dateCreated',
      headerName: 'Date',
      width: 175,
      headerAlign: 'center',
    },
    {
      field: 'cardName',
      headerName: 'Trello Card',
      width: 250,
      headerAlign: 'center',
    },
    {
      field: 'card',
      headerName: 'Trello Card Link',
      width: 250,
      headerAlign: 'center',
    },
    {
      field: 'label',
      headerName: 'Issue',
      width: 175,
      headerAlign: 'centers',
    },
    {
      field: 'actor',
      headerName: 'Actor',
      width: 175,
      headerAlign: 'centers',
    },
  ];
  const itemsOrderIssues = orderIssues
    ? orderIssues.map((item) => {
        return {
          id: `${item.PK}#${item.SK}`,
          dateCreated: dayjs(item.dateCreated).format('DD-MM-YYYY HH:mm:ss'),
          label: item.SK.split('-')[0],
          card: item.url,
          cardName: item.name,
          actor: item.GSI1SK,
        };
      })
    : [];

  const pastOrderIssuesColumn = orderIssuesColumn;

  const itemPastOrderIssues = pastOrderIssues
    ? pastOrderIssues.map((item) => {
        return {
          id: `${item.PK}#${item.SK}`,
          dateCreated: dayjs(item.dateCreated).format('DD-MM-YYYY HH:mm:ss'),
          label: item.SK.split('-')[0],
          card: item.url,
          cardName: item.name,
          actor: item.GSI1SK,
        };
      })
    : [];

  return !loadingOrderIssues && !loadingPastOrderIssues ? (
    <div className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h6'>Paperlust Order Issues</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant='subtitle1' component='h1'>
                This page is to track all issues with Paperlust order which is
                needed for KPI tracking
              </Typography>
              <Typography variant='subtitle2' className={classes.typography}>
                Sales Conversion
              </Typography>
              <Typography variant='body2'>
                SC3: Inaccurate pricing provided in the quote, quote being
                delayed without external factors
              </Typography>
              <Typography variant='body2'>
                SC4: Inaccurate pricing in invoice
              </Typography>
              <Typography variant='body2' gutterBottom>
                SC5: Quote details in Trello card didn't updated accordingly
              </Typography>
              <Typography variant='subtitle2' className={classes.typography}>
                Customer Engagement
              </Typography>
              <Typography variant='body2'>
                CE1: Provides inaccurate information, unresponded chats and
                messages, unanswered calls in work hours, unresponded emails
              </Typography>
              <Typography variant='body2' gutterBottom>
                CE3: Customer complaints for not receiving one or more
                information not because of system faulty
              </Typography>
              <Typography variant='subtitle2' className={classes.typography}>
                Product Quality
              </Typography>
              <Typography variant='body2'>
                PQ1: Typo, missing details in proofing, design proof takes more
                than 2 days without any reason
              </Typography>
              <Typography variant='body2'>
                PQ2: Wrong print file prepared, typo and missing details picked
                up in production
              </Typography>
              <Typography variant='body2'>
                PQ3: Print details in Trello card didn't updated accordingly
              </Typography>
              <Typography variant='body2'>
                PQ4: Typo and missing details didn't picked up during review
                process
              </Typography>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>

      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <Tab label='Current Month' {...a11yProps(0)} />
          <Tab label='Past Month' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div style={{ height: 400, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={itemsOrderIssues}
                columns={orderIssuesColumn}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div style={{ height: 400, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={itemPastOrderIssues}
                columns={pastOrderIssuesColumn}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </div>
        </div>
      </TabPanel>
    </div>
  ) : (
    <LinearProgress color='secondary' />
  );
};

OrderIssues.propTypes = {
  orderIssues: PropTypes.object.isRequired,
  getOrderIssues: PropTypes.func.isRequired,
  getPastOrderIssues: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  orderIssues: state.orderIssues,
  pastOrderIssues: state.pastOrderIssues,
});
export default connect(mapStateToProps, { getOrderIssues, getPastOrderIssues })(
  OrderIssues
);
