import {
  ADD_PHOTOBOOKS_CARD,
  GET_PHOTOBOOKS_CARDS,
  GET_PHOTOBOOKS_CARD,
} from '../actions/types';

const initialState = {
  photobooksCard: {},
  photobooksCards: [],
  loadingPhotobooksCard: true,
  error: {},
};

export default function photobooksCards(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PHOTOBOOKS_CARDS:
      return {
        ...state,
        photobooksCard: {},
        photobooksCards: payload,
        loadingPhotobooksCard: false,
      };
    case GET_PHOTOBOOKS_CARD:
      return {
        ...state,
        photobooksCard: payload,
        photobooksCards: [],
        loadingPhotobooksCard: false,
      };
    case ADD_PHOTOBOOKS_CARD:
      return {
        ...state,
        photobooksCard: payload,
        photobooksCards: [payload, ...state.photobooksCards],
        loadingPhotobooksCard: false,
      };
    default:
      return state;
  }
}
