export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const ADD_LIST = 'ADD_LIST';
export const ERROR_LIST = 'ERROR_LIST';
export const GET_LIST = 'GET_LIST';
export const GET_LISTS = 'GET_LISTS';
export const LISTS = 'LISTS';

export const ADD_DESIGN_CHALLENGE_LIST = 'ADD_DESIGN_CHALLENGE_LIST';
export const ERROR_DESIGN_CHALLENGE_LIST = 'ERROR_DESIGN_CHALLENGE_LIST';
export const GET_DESIGN_CHALLENGE_LIST = 'GET_DESIGN_CHALLENGE_LIST';
export const GET_DESIGN_CHALLENGE_LISTS = 'GET_DESIGN_CHALLENGE_LISTS';
export const DESIGN_CHALLENGE_LISTS = 'DESIGN_CHALLENGE_LISTS';

export const ADD_CARD = 'ADD_CARD';
export const ERROR_CARD = 'ERROR_CARD';
export const GET_CARD = 'GET_CARD';
export const GET_CARDS = 'GET_CARDS';
export const CARDS = 'CARDS';

export const ADD_DESIGN_CHALLENGE_CARD = 'ADD_DESIGN_CHALLENGE_CARD';
export const ERROR_DESIGN_CHALLENGE_CARD = 'ERROR_DESIGN_CHALLENGE_CARD';
export const GET_DESIGN_CHALLENGE_CARD = 'GET_DESIGN_CHALLENGE_CARD';
export const GET_DESIGN_CHALLENGE_CARDS = 'GET_DESIGN_CHALLENGE_CARDS';
export const DESIGN_CHALLENGE_CARDS = 'DESIGN_CHALLENGE_CARDS';

export const ADD_PHOTOBOOKS_CARD = 'ADD_PHOTOBOOKS_CARD';
export const ERROR_PHOTOBOOKS_CARD = 'ERROR_PHOTOBOOKS_CARD';
export const GET_PHOTOBOOKS_CARD = 'GET_PHOTOBOOKS_CARD';
export const GET_PHOTOBOOKS_CARDS = 'GET_PHOTOBOOKS_CARDS';
export const PHOTOBOOKS_CARDS = 'PHOTOBOOKS_CARDS';

export const ADD_PLATFORM_ORDER_LIST = 'ADD_PLATFORM_ORDER_LIST';
export const GET_PLATFORM_ORDER_LIST = 'GET_PLATFORM_ORDER_LIST';
export const GET_PLATFORM_ORDER_LISTS = 'GET_PLATFORM_ORDER_LISTS';

export const GET_METRICS = 'GET_METRICS';
export const ERROR_METRICS = 'ERROR_METRICS';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const GET_EXTRA_LABEL = 'GET_EXTRA_LABEL';
export const GET_ORDER_ISSUES = 'GET_ORDER_ISSUES';
export const GET_PAST_ORDER_ISSUES = 'GET_PAST_ORDER_ISSUES';

export const UPDATE_CUSTOM_ORDER_EXPIRATION_TIME =
  'UPDATE_CUSTOM_ORDER_EXPIRATION_TIME';
