import axios from 'axios';
import { setAlert } from './alert';
export const sync = (type) => async (dispatch) => {
  try {
    dispatch(setAlert(`Synchronising Trello ${type} data`, 'info'));
    await axios.get(`${process.env.REACT_APP_URL_BACKEND}/sync/${type}`);
    let additionalMessage =
      type == 'list'
        ? 'please check the list setting in list menu'
        : 'the turn over calculation will be updated shortly';
    dispatch(
      setAlert(
        `Trello ${type} data updated, ${additionalMessage}`,
        'success',
        process.env.REACT_APP_CUSTOM_TIMER
      )
    );
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};
