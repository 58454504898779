import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function AlertBox(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Alert = ({alert: {alert}}) => {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    let openState =
      Object.keys(alert).length === 0 && alert.constructor === Object
        ? false
        : true;
    setOpen(openState);
  }, [alert]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      key={alert.id}
      open={open}
      autoHideDuration={alert.timeout * 1}
      onClose={handleClose}
    >
      <AlertBox onClose={handleClose} severity={alert.alertType}>
        {alert.msg}
      </AlertBox>
    </Snackbar>
  );
};

Alert.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps)(Alert);
