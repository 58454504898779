import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {makeStyles} from '@material-ui/core/styles';

import {syncPlatformOrder} from '../../actions/platformOrderList';
import {sync} from '../../actions/sync';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonTop: {
    marginTop: theme.spacing(1),
  },
  card: {
    marginTop: theme.spacing(1),
  },
  field: {
    marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Configuration = ({sync, syncPlatformOrder}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSync = (e, type) => {
    sync(type);
  };

  const onSyncPlatformOrder = (e, type) => {
    syncPlatformOrder(type);
  };

  return (
    <Fragment>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label='Settings tab'
        >
          <Tab label='Custom Order Board' {...a11yProps(0)} />
          <Tab label='Platform Order Board' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Card className={classes.card}>
          <CardHeader
            title='Custom Order Board - Card Turn Over'
            subheader='Manually sync the trello data to the turn over calculation'
          ></CardHeader>
          <CardContent>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              onClick={(e) => onSync(e, 'lists')}
            >
              Sync Lists
            </Button>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              onClick={(e) => onSync(e, 'cards')}
            >
              Sync Cards
            </Button>
            {/* <Button
            className={classes.button}
            variant='contained'
            color='secondary'
            onClick={(e) => onSync(e, 'actions')}
          >
            Sync Card Actions
          </Button> */}
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Card className={classes.card}>
          <CardHeader
            title='Platform Order Board - Card Cleansheet'
            subheader='Select Trello list to show on the Platform Order Board'
          ></CardHeader>
          <CardContent>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              onClick={(e) => onSyncPlatformOrder(e, 'lists')}
            >
              Sync Lists
            </Button>
          </CardContent>
        </Card>
      </TabPanel>
    </Fragment>
  );
};

Configuration.propTypes = {
  sync: PropTypes.func.isRequired,
  syncPlatformOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  sync,
  syncPlatformOrder,
})(Configuration);
