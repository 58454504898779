import axios from 'axios';
import { GET_PLATFORM_ORDER_LISTS } from './types';
import { setAlert } from './alert';

export const getPlatformOrderLists = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND_PLATFORM}/lists`
    );
    const data = {
      platformList: res.data.items,
    };
    dispatch({
      type: GET_PLATFORM_ORDER_LISTS,
      payload: data.platformList,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};

export const getActivePlatformOrderLists = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND_PLATFORM}/lists?status=active`
    );
    const data = {
      platformList: res.data.items,
    };
    dispatch({
      type: GET_PLATFORM_ORDER_LISTS,
      payload: data.platformList,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};

export const syncPlatformOrder = (type) => async (dispatch) => {
  try {
    dispatch(setAlert(`Synchronising Trello ${type} data`, 'info'));
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND_PLATFORM}/lists`
    );
    let additionalMessage = 'please check the list setting below';
    dispatch(
      setAlert(
        `Trello ${type} data updated, ${additionalMessage}`,
        'success',
        process.env.REACT_APP_CUSTOM_TIMER
      )
    );
    const data = {
      platformList: res.data.items,
    };
    dispatch({
      type: GET_PLATFORM_ORDER_LISTS,
      payload: data.platformList,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};

export const updatePlatformOrderList = (data) => async (dispatch) => {
  const { newValue, rowData, columnDef } = data;
  try {
    dispatch(setAlert('Saving changes', 'info'));
    const dataToSend = {
      PK: rowData.PK,
      SK: rowData.SK,
      name: rowData.name,
      showOnList:
        columnDef.field == 'showOnList' ? newValue : rowData.showOnList,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND_PLATFORM}/lists/${rowData.id}`,
      dataToSend
    );

    const data = {
      lists: res.data.items,
    };
    dispatch({
      type: GET_PLATFORM_ORDER_LISTS,
      payload: data.lists,
    });
    dispatch(
      setAlert(
        'Trello list data updated',
        'success',
        process.env.REACT_APP_CUSTOM_TIMER
      )
    );
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};
