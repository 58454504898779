import {ADD_CARD, GET_CARDS, GET_CARD} from '../actions/types';

const initialState = {
  card: {},
  cards: [],
  loadingCard: true,
  error: {},
};

export default function cards(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_CARDS:
      return {
        ...state,
        card: {},
        cards: payload,
        loadingCard: false,
      };
    case GET_CARD:
      return {
        ...state,
        card: payload,
        cards: [],
        loadingCard: false,
      };
    case ADD_CARD:
      return {
        ...state,
        card: payload,
        cards: [payload, ...state.cards],
        loadingCard: false,
      };
    default:
      return state;
  }
}
