import { combineReducers } from 'redux';

import alert from './alert';
import auth from './auth';
import cache from './cache';
import cards from './cards';
import photobooksCards from './photobooksCards';
import extraInvoice from './extraInvoiceLabel';
import orderIssues from './orderIssues';
import pastOrderIssues from './pastOrderIssues';
import platformOrderLists from './platformOrderLists';
import designChallengesCards from './designChallengesCards';
import designChallengesLists from './designChallengesLists';
import lists from './lists';
import metrics from './metrics';

export default combineReducers({
  alert,
  auth,
  cache,
  cards,
  photobooksCards,
  extraInvoice,
  orderIssues,
  pastOrderIssues,
  designChallengesCards,
  designChallengesLists,
  platformOrderLists,
  lists,
  metrics,
});
