import { GET_PAST_ORDER_ISSUES } from '../actions/types';

const initialState = {
  pastOrderIssues: [],
  loadingPastOrderIssues: true,
  error: {},
};

export default function extraInvoice(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PAST_ORDER_ISSUES:
      return {
        ...state,
        pastOrderIssues: payload,
        loadingPastOrderIssues: false,
      };
    default:
      return state;
  }
}
