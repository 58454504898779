import { GET_ORDER_ISSUES } from '../actions/types';

const initialState = {
  orderIssues: [],
  loadingOrderIssues: true,
  error: {},
};

export default function extraInvoice(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_ISSUES:
      return {
        ...state,
        orderIssues: payload,
        loadingOrderIssues: false,
      };
    default:
      return state;
  }
}
