import {SET_ALERT, REMOVE_ALERT} from '../actions/types';
const initialState = {
  alert: {},
};

export default function alert(state = initialState, action) {
  switch (action.type) {
    case SET_ALERT:
      return {...state, alert: action.payload};
    case REMOVE_ALERT:
      return {...state, alert: {}};
    default:
      return state;
  }
}
