import axios from 'axios';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import {
  GET_CARDS,
  CARDS,
  GET_PHOTOBOOKS_CARDS,
  PHOTOBOOKS_CARDS,
  UPDATE_CUSTOM_ORDER_EXPIRATION_TIME,
} from './types';
import { setAlert } from './alert';
import { read, write } from '../utils/cache';

export const getCards = (parameters) => async (dispatch) => {
  try {
    const cards = read(CARDS);
    const timeCreation = Date.now();
    const res = !cards
      ? await axios.get(process.env.REACT_APP_URL_BACKEND + '/cards')
      : cards;
    if (!cards) {
      // res.time = timeCreation;
      // write(CARDS, res);
    }
    dispatch({
      type: UPDATE_CUSTOM_ORDER_EXPIRATION_TIME,
      payload: !cards ? timeCreation : res.time,
    });

    const data = {
      cards: res.data.items.Items.filter((card) =>
        card.name.toLowerCase().includes('design-print')
      ),
    };

    if (parameters) {
      let items = [];
      const { start, end } = parameters;
      dayjs.extend(isBetween);
      data.cards.forEach((card) => {
        if (
          dayjs
            .unix(card.dateCreated)
            .isBetween(
              dayjs(start).format('MM-DD-YYYY HH:mm:ss'),
              dayjs(end).format('MM-DD-YYYY HH:mm:ss')
            )
        ) {
          items.push(card);
        }
      });
      dispatch({
        type: GET_CARDS,
        payload: items,
      });
    } else {
      dispatch({
        type: GET_CARDS,
        payload: data.cards,
      });
    }
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};

export const getPhotobookCards = (parameters) => async (dispatch) => {
  try {
    const cards = read(PHOTOBOOKS_CARDS);
    const timeCreation = Date.now();
    const res = !cards
      ? await axios.get(process.env.REACT_APP_URL_BACKEND + '/cards')
      : cards;
    if (!cards) {
      // res.time = timeCreation;
      // write(CARDS, res);
    }
    dispatch({
      type: UPDATE_CUSTOM_ORDER_EXPIRATION_TIME,
      payload: !cards ? timeCreation : res.time,
    });

    const data = {
      cards: res.data.items.Items.filter((card) =>
        card.name.toLowerCase().includes('photobook')
      ),
    };

    if (parameters) {
      let items = [];
      const { start, end } = parameters;
      dayjs.extend(isBetween);
      data.cards.forEach((card) => {
        if (
          dayjs
            .unix(card.dateCreated)
            .isBetween(
              dayjs(start).format('MM-DD-YYYY HH:mm:ss'),
              dayjs(end).format('MM-DD-YYYY HH:mm:ss')
            )
        ) {
          items.push(card);
        }
      });
      dispatch({
        type: GET_PHOTOBOOKS_CARDS,
        payload: items,
      });
    } else {
      dispatch({
        type: GET_PHOTOBOOKS_CARDS,
        payload: data.cards,
      });
    }
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.error(error);
  }
};

export const syncCard = (data) => async (dispatch) => {
  try {
    dispatch(setAlert('Synchronising Trello card changes', 'info'));
    const dataToSend = {
      id: data,
    };
    await axios.post(`${process.env.REACT_APP_URL_BACKEND}/card`, dataToSend);
    dispatch(
      setAlert(
        'Card data updated, the turn over calculation will be updated shortly (please reload the browser)',
        'success',
        process.env.REACT_APP_CUSTOM_TIMER
      )
    );
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
