import {GET_METRICS} from '../actions/types';

const initialState = {
  metric: {},
  metrics: [],
  loadingMetrics: true,
  error: {},
};

export default function metrics(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_METRICS:
      return {
        ...state,
        metric: {},
        metrics: payload,
        loadingMetrics: false,
      };
    default:
      return state;
  }
}
